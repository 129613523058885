.dot-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: gray;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: black;
  }